/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

let navMenuItems = [];

let parentMenu = [
  {
    url: null,
    name: "Parent",
    tag: "2",
    tagColor: "warning",
    icon: "",
    i18n: "My Children",
    submenu: [
      {
        url: "/dashboard/analytics",
        name: "Analytics",
        slug: "dashboard-analytics",
        i18n: "Analytics"
      },
      {
        url: "/dashboard/ecommerce",
        name: "eCommerce",
        slug: "dashboard-ecommerce",
        i18n: "eCommerce"
      }
    ]
  }
];

let teacherMenu = [
  {
    url: "/teacher/dashboard",
    name: "teacher-dashboard",
    icon: "",
    i18n: "Dashboard",
    slug: "dashboard",
    feature: ""
  },
  {
    url: "/teacher/daily-diary/",
    name: "Daily Diary",
    icon: "",
    i18n: "Daily Diary",
    slug: "daily-diary",
    feature: "daily_diary_of_children_play"
  },
  {
    url: "/teacher/documentation/",
    name: "Documentation",
    icon: "",
    i18n: "Documentation",
    slug: "documentation",
    feature: "activity_planner"
  },

  {
    url: "/teacher/learning-story/",
    name: "Learning Story",
    icon: "",
    i18n: "Learning Story",
    slug: "LearningStory",
    feature: "activity_planner"
  },

  {
    //url: '',
    url: "/teacher/self-assessment/",
    name: "Self Assessment",
    icon: "",
    i18n: "Self Assessment",
    slug: "self-assessment",
    feature: "professional_development"
  },
  {
    url: "/teacher/professional-development",
    name: "Professional Development",
    icon: "",
    i18n: "Professional Development",
    slug: "center-admin-professional-development",
    feature: "",
    submenu: [
      {
        url: "/teacher/professional-development/fast-check",
        name: "Fast Check",
        slug: "professional-development-teacher-fast-check",
        i18n: "Fast Check"
      },
      {
        url: "/teacher/professional-development/core-skill",
        name: "Core Skill",
        slug: "professional-development-teacher-core-skill",
        i18n: "Core Skill"
      }
    ]
  },
  {
    url: "/teacher/my-insight",
    name: "my-insight",
    icon: "",
    i18n: "My Insights",
    slug: "my-insight",
    feature: "dynamic_centre_insights"
  },
  {
    url: "/teacher/tutorial/",
    name: "Tutorials",
    icon: "",
    i18n: "Tutorials",
    slug: "tutorials",
    feature: ""
  },
  /*{
    url: '',
    name: "Center Professional Development",
    icon: "",
    i18n: "Center Professional Development",
    slug: "center-professional-development",
  },*/
  {
    url: "/teacher/friendship-circle",
    name: "Friendship Circle",
    icon: "",
    i18n: "Friendship Circle",
    slug: "friendship-circle",
    feature: ""
  }
];

let centerAdminMenu = [
  {
    url: "/center-admin/dashboard",
    name: "Dashboard",
    icon: "",
    i18n: "Dashboard",
    slug: "dashboard",
    feature: ""
  },
  {
    url: "/center-admin/insights",
    name: "Center Insights",
    icon: "",
    i18n: "Center Insights",
    slug: "center-insights",
    feature: "dynamic_centre_insights"
  },
  {
    url: "/center-admin/newsletters",
    name: "Newsletter",
    icon: "",
    i18n: "Newsletter",
    slug: "newsletter",
    feature: "newsletter_creation"
  },
  {
    url: "/center-admin/friendship-circle",
    //url: '/teacher/friendship-circles/',
    name: "Friendship Circles",
    icon: "",
    i18n: "Friendship Circles",
    slug: "friendship-circles",
    feature: ""
  },
  {
    url: "/center-admin/learning-story/",
    name: "Learning Story",
    icon: "",
    i18n: "Learning Story",
    slug: "LearningStory",
    feature: "activity_planner"
  },
  {
    url: "/center-admin/professional-development",
    name: "Professional Development",
    icon: "",
    i18n: "Professional Development",
    slug: "center-admin-professional-development",
    feature: "",
    submenu: [
      {
        url: "/center-admin/professional-development/fast-check",
        name: "Fast Check",
        slug: "professional-development-center-admin-fast-check",
        i18n: "Fast Check"
      },
      {
        url: "/center-admin/professional-development/core-skill",
        name: "Core Skill",
        slug: "professional-development-center-admin-core-skill",
        i18n: "Core Skill"
      }
    ]
  },
  {
    url: "/center-admin/teachers",
    name: "Teachers",
    icon: "",
    i18n: "Teachers",
    slug: "teachers",
    feature: ""
  },
  {
    url: "/center-admin/rooms",
    name: "Rooms",
    icon: "",
    i18n: "Rooms",
    slug: "Rooms",
    feature: ""
  },
  {
    url: "/center-admin/children",
    name: "Children",
    icon: "",
    i18n: "Children",
    slug: "children",
    feature: ""
  },
  {
    url: "/center-admin/nqs-report",
    name: "NQS Report",
    icon: "",
    i18n: "NQS Report",
    slug: "NQS Report",
    feature: ""
  },
  {
    url: "/center-admin/about-us",
    name: "About Us",
    icon: "",
    i18n: "About Us",
    slug: "about-us",
    feature: ""
  },
  {
    url: "/center-admin/philosophies",
    name: "center-admin-philosophies",
    icon: "",
    i18n: "Philosophies",
    slug: "Philosophies",
    feature: ""
  },
  {
    url: "/center-admin/support",
    name: "Support",
    icon: "",
    i18n: "Support",
    slug: "support",
    feature: ""
  }
];

let centerDirectorMenu = [
  {
    url: "/director/dashboard",
    name: "Dashboard",
    icon: "",
    i18n: "Dashboard",
    slug: "dashboard",
    feature: ""
  },
  {
    url: "/director/learning-centers",
    name: "Centres",
    icon: "",
    i18n: "Centres",
    slug: "centres",
    feature: "dynamic_centre_insights"
  },

  {
    url: "/director/newsletters",
    name: "Newsletters",
    icon: "",
    i18n: "Newsletters",
    slug: "newsletters",
    feature: "newsletter_creation"
  },

  /*   {
    url: '/director/teachers',
    name: "Teachers",
    icon: "",
    i18n: "Teachers",
    slug: "Teachers",
  }, */
  {
    url: "/director/insights",
    name: "Insights",
    icon: "",
    i18n: "Insights",
    slug: "insights",
    feature: "dynamic_centre_insights"
  },
  {
    url: "/director/regional-director/list",
    name: "Regional Directors",
    icon: "",
    i18n: "Regional Directors",
    slug: "Regional Directors",
    feature: "regional_director"
  },
  {
    url: "/director/learning-story/",
    name: "Learning Story",
    icon: "",
    i18n: "Learning Story",
    slug: "LearningStory",
    feature: "activity_planner"
  },
  {
    url: "/director/subscription",
    name: "Subscription",
    icon: "",
    i18n: "Subscription",
    slug: "subscription",
    feature: ""
  },
  /*  {
    url: '',
    name: "NQS Reports",
    icon: "",
    i18n: "NQS Reports",
    slug: "nqs-reports",
  }, */
  {
    url: "/director/about-us",
    name: "About Us",
    icon: "",
    i18n: "About Us",
    slug: "about-us",
    feature: ""
  },
  {
    url: "/director/philosophies",
    name: "Philosophies",
    icon: "",
    i18n: "Philosophies",
    slug: "Philosophies",
    feature: ""
  },
  {
    url: "/director/support",
    name: "Support",
    icon: "",
    i18n: "Support",
    slug: "support",
    feature: ""
  }
];

let centerRegionalDirectorMenu = [
  {
    url: "/director/dashboard",
    name: "Dashboard",
    icon: "",
    i18n: "Dashboard",
    slug: "dashboard",
    feature: ""
  },
  {
    url: "/director/learning-centers",
    name: "Centres",
    icon: "",
    i18n: "Centres",
    slug: "centres",
    feature: "dynamic_centre_insights"
  },
  {
    url: "/director/learning-story/",
    name: "Learning Story",
    icon: "",
    i18n: "Learning Story",
    slug: "LearningStory",
    feature: "activity_planner"
  },

  {
    url: "/director/newsletters",
    name: "Newsletters",
    icon: "",
    i18n: "Newsletters",
    slug: "newsletters",
    feature: "newsletter_creation"
  },

  /*   {
    url: '/director/teachers',
    name: "Teachers",
    icon: "",
    i18n: "Teachers",
    slug: "Teachers",
  }, */
  {
    url: "/director/insights",
    name: "Insights",
    icon: "",
    i18n: "Insights",
    slug: "insights",
    feature: "dynamic_centre_insights"
  },

  /*  {
    url: '',
    name: "NQS Reports",
    icon: "",
    i18n: "NQS Reports",
    slug: "nqs-reports",
  }, */
  {
    url: "/director/about-us",
    name: "About Us",
    icon: "",
    i18n: "About Us",
    slug: "about-us",
    feature: ""
  },
  {
    url: "/director/philosophies",
    name: "Philosophies",
    icon: "",
    i18n: "Philosophies",
    slug: "Philosophies",
    feature: ""
  },
  {
    url: "/director/support",
    name: "Support",
    icon: "",
    i18n: "Support",
    slug: "support",
    feature: ""
  }
];

let adminMenu = [
  {
    url: "/admin/dashboard/",
    name: "Dashboard",
    icon: "",
    i18n: "Dashboard",
    slug: "dashboard"
    /*submenu: [
      {
        url: '/admin/learning-center-presets/assessment-subjects',
        name: "Assessment Subjects",
        slug: "assessment-subject",
        i18n: "Assessment Subjects",
      },
      {
        url: '/admin/learning-center-presets/children-stories',
        name: "Children Stories",
        slug: "children-stories",
        i18n: "Children Stories",
      },
      {
        url: '/admin/learning-center-presets/fast-check',
        name: "Fast Check",
        slug: "fast-check",
        i18n: "Fast Check",
      },
      {
        url: '/admin/learning-center-presets/early-years-learning-framework',
        name: "Assessment Subjects Stream",
        slug: "early-years-learning-framework",
        i18n: "Early Years Learning Framework",
      },
    ]*/
  },
  {
    url: "/admin/learning-centers/",
    name: "Learning Centers",
    icon: "",
    i18n: "Learning Centres",
    slug: "learning-centres"
  },
  {
    url: "/admin/learning-center-presets/",
    name: "Learning Center Presets",
    icon: "",
    i18n: "Learning Centres Presets",
    slug: "learning-center-presets",
    submenu: [
      {
        url: "/admin/learning-center-presets/assessment-subjects",
        name: "Assessment Subjects",
        slug: "assessment-subject",
        i18n: "Assessment Subjects"
      },
      {
        url: "/admin/learning-center-presets/children-stories",
        name: "Children Stories",
        slug: "children-stories",
        i18n: "Children Stories"
      },
      {
        url: "/admin/learning-center-presets/fast-check",
        name: "Fast Check",
        slug: "fast-check",
        i18n: "Fast Check"
      },
      {
        url:
          "/admin/learning-center-presets/early-years-learning-framework-category",
        name: "Early Learning Framework Category",
        slug: "early-years-learning-framework-category",
        i18n: "Early Learning Framework Category"
      },
      {
        url: "/admin/learning-center-presets/early-years-learning-framework",
        name: "Assessment Subjects Stream",
        slug: "early-years-learning-framework",
        i18n: "Early Learning Framework"
      }
    ]
  },
  {
    url: "/admin/professional-development/",
    name: "Professional Development",
    icon: "",
    i18n: "Professional Development",
    slug: "professional-development",
    submenu: [
      {
        url: "/admin/professional-development/fast-check",
        name: "Fast Check",
        slug: "professional-development-fast-check",
        i18n: "Fast Check"
      },
      {
        url: "/admin/professional-development/core-skill",
        name: "Core Skill",
        slug: "professional-development-core-skill",
        i18n: "Core Skill"
      }
    ]
  },
  {
    url: "/admin/tutorial/",
    name: "Tutorial",
    icon: "",
    i18n: "Tutorial",
    slug: "tutorial"
  },
  {
    url: "/admin/country/",
    name: "Country",
    icon: "",
    i18n: "Country",
    slug: "country"
  },
  {
    url: "/admin/age-group/",
    name: "Age Group",
    icon: "",
    i18n: "Age Group",
    slug: "age-group"
  },
  {
    url: "/admin/support/",
    name: "Support",
    icon: "",
    i18n: "Support",
    slug: "support"
  },
  {
    url: "/admin/admin-management/",
    name: "Admin Management",
    icon: "",
    i18n: "Admin Management",
    slug: "admin-management"
  },
  {
    url: "/admin/pages/",
    name: "Page Management",
    icon: "",
    i18n: "Page Management",
    slug: "pages"
  },
  {
    url: "/admin/user-subscriptions/",
    name: "User Subscriptions",
    icon: "",
    i18n: "User Subscriptions",
    slug: "user-subscriptions"
  },
  {
    url: null,
    name: "Settings",
    icon: "",
    i18n: "Settings",
    slug: "settings",
    submenu: [
      {
        url: "/admin/settings/email-template-management/",
        name: "Email Template Management",
        i18n: "Email Template Management",
        slug: "email-template"
      },
      {
        url: "/admin/settings/content",
        name: "Content",
        slug: "content",
        i18n: "Content"
      },
      {
        url: "/admin/settings/unique-selling-point",
        name: "Unique Selling Point",
        slug: "unique-selling-point",
        i18n: "Unique Selling Point"
      },
      {
        url: "/admin/settings/testimonials",
        name: "Testimonials",
        slug: "testimonials",
        i18n: "Testimonials"
      },
      {
        url: "/admin/settings/faq",
        name: "FAQ",
        slug: "faq",
        i18n: "FAQ"
      },
      {
        url: "/admin/settings/subscription-package",
        name: "Subscription Package",
        slug: "subscription-package",
        i18n: "Subscription Package"
      },
      {
        url: "/admin/settings/package-inclusion",
        name: "Package Inclusion",
        slug: "package-inclusion",
        i18n: "Package Inclusion"
      }
    ]
  }
];

export {
  navMenuItems,
  parentMenu,
  teacherMenu,
  centerAdminMenu,
  centerDirectorMenu,
  centerRegionalDirectorMenu,
  adminMenu
};
