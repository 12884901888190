<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    redirectPage(link) {
      this.$router.push({ name: link }).catch(() => {});
    },
    logout() {
      // if user is logged in via auth0
      if (this.$auth.profile) this.$auth.logOut();

      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser;

      if (firebaseCurrentUser) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.push("/login").catch(() => {});
          });
      }

      this.$acl.change("guest");

      // If JWT login
      if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
        )
      ) {
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}refreshToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}firstName`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}tokenExpiry`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}loggedIn`
        );

        if (
          this.activeUserInfo.userType == "superAdmin" ||
          this.activeUserInfo.userType == "admin"
        ) {
          this.$router.push("/admin/login").catch(() => {});
        }else{
          this.$router.push("/login").catch(() => {});
        }

        // else if (this.activeUserInfo.userType == "teacher") {
        //   this.$router.push("/teacher/login").catch(() => {});
        // } else if (this.activeUserInfo.userType == "parent") {
        //   this.$router.push("/parent/login").catch(() => {});
        // } else if (this.activeUserInfo.userType == "learningCenter") {
        //   if (this.activeUserInfo.userRole == "centreDirector") {
        //     this.$router.push("/director/login").catch(() => {});
        //   } else if (this.activeUserInfo.userRole == "centerAdmin") {
        //     this.$router.push("/center-admin/login").catch(() => {});
        //   }
        // } else if (
        //   this.activeUserInfo.userType == "parent" ||
        //   this.activeUserInfo.userType == "guardians" ||
        //   this.activeUserInfo.userType == "guardians"
        // ) {
        //   this.$router.push("/parent/login").catch(() => {});
        // }
      }

      // Change role on logout. Same value as initialRole of acj.js

      localStorage.removeItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`
      );

      // This is just for demo Purpose. If user clicks on logout -> redirect
      if (
        this.activeUserInfo.userType == "superAdmin" ||
        this.activeUserInfo.userType == "admin"
      ) {
        this.$router.push("/admin/login").catch(() => {});
      } else{
        this.$router.push("/login").catch(() => {});
      }


      // else if (this.activeUserInfo.userType == "teacher") {
      //   this.$router.push("/teacher/login").catch(() => {});
      // } else if (this.activeUserInfo.userType == "learningCenter") {
      //   if (this.activeUserInfo.role == "centreDirector") {
      //     this.$router.push("/director/login").catch(() => {});
      //   } else if (this.activeUserInfo.role == "centerAdmin") {
      //     this.$router.push("/center-admin/login").catch(() => {});
      //   }
      // } else if (this.activeUserInfo.userType == "parent") {
      //   this.$router.push("/parent/login").catch(() => {});/
      // }
    }
  }
};
</script>

<template>
  <div class="the-navbar__user-meta flex items-center">
    <div class="text-right leading-tight hidden sm:block">
      <p style="font-size: 15px; line-height: 20px; color: #289AD5;">
        {{ activeUserInfo.fullName }}
      </p>
    </div>

    <vs-dropdown
      vs-custom-content
      vs-trigger-click
      class="cursor-pointer"
      ref="abc"
    >
      <div class="con-img ml-sm-3">
        <img
          key="onlineImg"
          :src="
            '' != activeUserInfo.profileImage &&
            'test.jop' != activeUserInfo.profileImage
              ? activeUserInfo.profileImage
              : $defaultProfileImage
          "
          alt="user-img"
          width="48"
          height="48"
          class="rounded-full shadow-md cursor-pointer block"
          style="object-fit: cover;"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown main-user-dropdown">
        <ul style="min-width: 9rem">
          <li
            v-if="
              activeUserInfo.userType == 'superAdmin' ||
                activeUserInfo.userType == 'admin'
            "
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('admin-profile')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            v-else-if="activeUserInfo.userType == 'teacher'"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('teacher-profile')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            v-else-if="
              activeUserInfo.userType == 'learningCenter' &&
                activeUserInfo.role == 'centreDirector'
            "
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('director-profile')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            v-else-if="
              activeUserInfo.userType == 'learningCenter' &&
                activeUserInfo.role == 'centerAdmin'
            "
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('center-admin-profile')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            v-if="activeUserInfo.userType === 'parent'"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('guardian-list')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Parent information</span>
          </li>

          <li
            v-if="activeUserInfo.userType === 'parent'"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('access-list')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Share access</span>
          </li>
          <li
            v-if="
              activeUserInfo.userType === 'parent' ||
                activeUserInfo.userType === 'guardians' ||
                activeUserInfo.userType === 'guardian'
            "
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectPage('parent-profile')"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Settings</span>
          </li>
          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>
