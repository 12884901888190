<template>
  <div>

  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  mounted() {
  },
}
</script>
