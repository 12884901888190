var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout--main",class:[_vm.layoutTypeClass, _vm.navbarClasses, _vm.footerClasses, {'no-scroll': _vm.isAppPage}]},[(!_vm.disableThemeTour && (_vm.windowWidth >= 1200 && _vm.mainLayoutType === 'vertical' && _vm.verticalNavMenuWidth == 'default'))?_c('vx-tour',{attrs:{"steps":_vm.steps}}):_vm._e(),(!_vm.disableCustomizer)?_c('the-customizer',{attrs:{"footerType":_vm.footerType,"hideScrollToTop":_vm.hideScrollToTop,"navbarType":_vm.navbarType,"navbarColor":_vm.navbarColor,"routerTransition":_vm.routerTransition},on:{"toggleHideScrollToTop":_vm.toggleHideScrollToTop,"updateFooter":_vm.updateFooter,"updateNavbar":_vm.updateNavbar,"updateNavbarColor":_vm.updateNavbarColor,"updateRouterTransition":_vm.updateRouterTransition}}):_vm._e(),(_vm.user.userType ==='parent' || _vm.user.userType ==='guardian' || _vm.user.userType ==='guardians')?_c('parent-vertical-nav-menu',{attrs:{"compressNavMenu":_vm.compressNavMenu,"navMenuItems":_vm.MainMenuItems,"title":"Personhood","parent":".layout--main"}}):_c('v-nav-menu',{attrs:{"compressNavMenu":_vm.compressNavMenu,"navMenuItems":_vm.MainMenuItems,"title":"Personhood","parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, {'show-overlay': _vm.bodyOverlay}],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
        {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
        {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
      ],attrs:{"navbarType":_vm.navbarType}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),_c('h-nav-menu',{class:[
        {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
        {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
      ],attrs:{"navMenuItems":_vm.MainMenuItems}})]:[_c('the-navbar-vertical',{class:[
        {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
        {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
      ],attrs:{"navbarColor":_vm.navbarColor},on:{"compressNavMenuCallBack":_vm.compressNavMenuAction}})],_c('div',{staticClass:"content-wrapper",on:{"click":function($event){return _vm.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);}}},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('transition',{attrs:{"name":_vm.routerTransition}},[(_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('div',{staticClass:"router-header flex flex-wrap items-center mb-6"},[_c('div',{staticClass:"content-area__heading",class:{'pr-4 border-0 md:border-r border-solid border-grey-light' : _vm.$route.meta.breadcrumb}},[_c('h2',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.routeTitle))])]),(_vm.$route.meta.breadcrumb)?_c('vx-breadcrumb',{staticClass:"ml-4 md:block hidden",attrs:{"route":_vm.$route,"dynamicTitle":_vm.dynamicTitle,"customBreadcrumbData":_vm.customBreadcrumbData,"isRTL":_vm.$vs.rtl}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"content-area__content"},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"changeRouteTitle":_vm.changeRouteTitle,"changeBreadcrumbTitle":_vm.changeBreadcrumbTitle,"customBreadCrumb":_vm.customBreadCrumb,"setAppClasses":function (classesStr) { return _vm.$emit('setAppClasses', classesStr); }}})],1)],1)],1)])]),_c('the-footer')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }