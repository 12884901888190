var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vx-navbar-wrapper",class:_vm.classObj},[_c('vs-navbar',{staticClass:"vx-navbar navbar-custom navbar-skelton header-loged-in",class:_vm.textColor,attrs:{"color":_vm.navbarColorLocal}},[_c('span',{staticClass:"menu-icon",on:{"click":function($event){$event.stopPropagation();return _vm.showSidebar.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"sm:inline-flex cursor-pointer sm:pr-2",attrs:{"icon":"MenuIcon"}}),_c('span',{staticClass:"hidden sm:block"},[_vm._v("Menu")])],1),(
          _vm.activeUserInfo.userType == 'admin' ||
            _vm.activeUserInfo.userType == 'superAdmin'
        )?_c('img',{staticClass:"inline-block logo-image",attrs:{"src":require("../../../assets/images/logo/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'admin-dashboard' }).catch(function () {})}}}):(_vm.activeUserInfo.userType == 'teacher')?_c('img',{staticClass:"inline-block logo-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/images/logo/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'teacher-dashboard' }).catch(function () {})}}}):(_vm.activeUserInfo.userType == 'parent')?_c('img',{staticClass:"inline-block logo-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/images/logo/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'parent-dashboard' }).catch(function () {})}}}):(
          _vm.activeUserInfo.userType == 'learningCenter' &&
            _vm.activeUserInfo.role == 'centreDirector'
        )?_c('img',{staticClass:"inline-block logo-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/images/logo/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'director-dashboard' }).catch(function () {})}}}):(
          _vm.activeUserInfo.userType == 'learningCenter' &&
            _vm.activeUserInfo.role == 'centerAdmin'
        )?_c('img',{staticClass:"inline-block logo-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/images/logo/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'center-admin-dashboard' }).catch(function () {})}}}):(
          _vm.activeUserInfo.userType === 'parent' ||
            _vm.activeUserInfo.userType === 'guardians' ||
            _vm.activeUserInfo.userType === 'guardian'
        )?_c('img',{staticClass:"inline-block logo-image",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/images/logo/logo.png")},on:{"click":function($event){_vm.$router.push({ name: 'parent-dashboard' }).catch(function () {})}}}):_vm._e(),_c('vs-spacer'),_c('notification-drop-down'),_c('profile-drop-down')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }