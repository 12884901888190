var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"the-navbar__user-meta flex items-center"},[_c('div',{staticClass:"text-right leading-tight hidden sm:block"},[_c('p',{staticStyle:{"font-size":"15px","line-height":"20px","color":"#289AD5"}},[_vm._v(" "+_vm._s(_vm.activeUserInfo.fullName)+" ")])]),_c('vs-dropdown',{ref:"abc",staticClass:"cursor-pointer",attrs:{"vs-custom-content":"","vs-trigger-click":""}},[_c('div',{staticClass:"con-img ml-sm-3"},[_c('img',{key:"onlineImg",staticClass:"rounded-full shadow-md cursor-pointer block",staticStyle:{"object-fit":"cover"},attrs:{"src":'' != _vm.activeUserInfo.profileImage &&
          'test.jop' != _vm.activeUserInfo.profileImage
            ? _vm.activeUserInfo.profileImage
            : _vm.$defaultProfileImage,"alt":"user-img","width":"48","height":"48"}})]),_c('vs-dropdown-menu',{staticClass:"vx-navbar-dropdown main-user-dropdown"},[_c('ul',{staticStyle:{"min-width":"9rem"}},[(
            _vm.activeUserInfo.userType == 'superAdmin' ||
              _vm.activeUserInfo.userType == 'admin'
          )?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('admin-profile')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Profile")])],1):(_vm.activeUserInfo.userType == 'teacher')?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('teacher-profile')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Profile")])],1):(
            _vm.activeUserInfo.userType == 'learningCenter' &&
              _vm.activeUserInfo.role == 'centreDirector'
          )?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('director-profile')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Profile")])],1):(
            _vm.activeUserInfo.userType == 'learningCenter' &&
              _vm.activeUserInfo.role == 'centerAdmin'
          )?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('center-admin-profile')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Profile")])],1):_vm._e(),(_vm.activeUserInfo.userType === 'parent')?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('guardian-list')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Parent information")])],1):_vm._e(),(_vm.activeUserInfo.userType === 'parent')?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('access-list')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Share access")])],1):_vm._e(),(
            _vm.activeUserInfo.userType === 'parent' ||
              _vm.activeUserInfo.userType === 'guardians' ||
              _vm.activeUserInfo.userType === 'guardian'
          )?_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":function($event){return _vm.redirectPage('parent-profile')}}},[_c('feather-icon',{attrs:{"icon":"UserIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Settings")])],1):_vm._e(),_c('vs-divider',{staticClass:"m-1"}),_c('li',{staticClass:"flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",on:{"click":_vm.logout}},[_c('feather-icon',{attrs:{"icon":"LogOutIcon","svgClasses":"w-4 h-4"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Logout")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }