<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton header-loged-in"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <span class="menu-icon" @click.stop="showSidebar">
          <feather-icon
            class="sm:inline-flex cursor-pointer sm:pr-2"
            icon="MenuIcon"
          />
          <span class="hidden sm:block">Menu</span>
        </span>
        <img
          v-if="
            activeUserInfo.userType == 'admin' ||
              activeUserInfo.userType == 'superAdmin'
          "
          src="../../../assets/images/logo/logo.png"
          class="inline-block logo-image"
          @click="$router.push({ name: 'admin-dashboard' }).catch(() => {})"
        />
        <img
          v-else-if="activeUserInfo.userType == 'teacher'"
          src="../../../assets/images/logo/logo.png"
          class="inline-block logo-image"
          @click="$router.push({ name: 'teacher-dashboard' }).catch(() => {})"
          style="cursor: pointer"
        />

        <img
          v-else-if="activeUserInfo.userType == 'parent'"
          src="../../../assets/images/logo/logo.png"
          class="inline-block logo-image"
          @click="$router.push({ name: 'parent-dashboard' }).catch(() => {})"
          style="cursor: pointer"
        />
        <img
          v-else-if="
            activeUserInfo.userType == 'learningCenter' &&
              activeUserInfo.role == 'centreDirector'
          "
          src="../../../assets/images/logo/logo.png"
          class="inline-block logo-image"
          @click="$router.push({ name: 'director-dashboard' }).catch(() => {})"
          style="cursor: pointer"
        />
        <img
          v-else-if="
            activeUserInfo.userType == 'learningCenter' &&
              activeUserInfo.role == 'centerAdmin'
          "
          src="../../../assets/images/logo/logo.png"
          class="inline-block logo-image"
          @click="
            $router.push({ name: 'center-admin-dashboard' }).catch(() => {})
          "
          style="cursor: pointer"
        />
        <img
          v-else-if="
            activeUserInfo.userType === 'parent' ||
              activeUserInfo.userType === 'guardians' ||
              activeUserInfo.userType === 'guardian'
          "
          src="../../../assets/images/logo/logo.png"
          class="inline-block logo-image"
          @click="$router.push({ name: 'parent-dashboard' }).catch(() => {})"
          style="cursor: pointer"
        />
        <!--        <img v-else-if="activeUserInfo.userType == 'teacher'" :src="activeUserInfo.learningCenter.logo ? activeUserInfo.learningCenter.logo : $defaultImage" class="inline-block" style="object-fit: scale-down; width: 115px; height: 60px;">-->
        <!-- SM - OPEN SIDEBAR BUTTON -->

        <!--<bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" />-->

        <vs-spacer />

        <!-- <i18n />-->

        <!--<search-bar />-->

        <!--<cart-drop-down />-->

        <!--<notification-drop-down />-->
        <notification-drop-down />

        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Bookmarks from "./components/Bookmarks.vue";
import I18n from "./components/I18n.vue";
import SearchBar from "./components/SearchBar.vue";
import CartDropDown from "./components/CartDropDown.vue";
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff"
    }
  },
  components: {
    Bookmarks,
    I18n,
    SearchBar,
    CartDropDown,
    NotificationDropDown,
    ProfileDropDown
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark")
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    isVerticalNavMenuActive: {
      get() {
        return this.$store.state.isVerticalNavMenuActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", val);
      }
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") {
        if (this.isVerticalNavMenuActive) {
          return "navbar-default navbar-increased";
        }
        return "navbar-default";
      } else if (this.verticalNavMenuWidth == "reduced") {
        if (this.isVerticalNavMenuActive) {
          return "navbar-reduced navbar-increased";
        }
        return "navbar-reduced";
      } else if (this.verticalNavMenuWidth) {
        if (this.isVerticalNavMenuActive) {
          return "navbar-full navbar-increased";
        }
        return "navbar-full";
      }
      // if (this.verticalNavMenuWidth == "default")  return "navbar-default"
      // else if (this.verticalNavMenuWidth == "reduced") return "navbar-full"
      // else if (this.verticalNavMenuWidth) return "navbar-full"
    }
  },
  methods: {
    showSidebar() {
      // this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      //this.$store.commit('TOGGLE_REDUCE_BUTTON', false)

      //this.$store.dispatch('updateVerticalNavMenuWidth', "default")
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);

      // toggleReduce
      //this.$store.commit('COMPRESS_NAV_MENU', false);
      // this.$emit('compressNavMenuCallBack', false)
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-image {
  object-fit: scale-down;
  width: 100px;
  height: 50px;
  margin-left: 15px;
  @media (min-width: 576px) {
    margin-left: 20px;
  }
  @media (min-width: 767px) {
    width: 115px;
    height: 60px;
    margin-left: 40px;
  }
}
.vx-navbar-wrapper {
  width: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  .vx-navbar {
    border-radius: 0 !important;
    padding: 20px 25px !important;
    transition: 0.3s all ease-in-out;
    @media (min-width: 1640px) {
      padding: 25px !important;
    }
    .the-navbar__user-meta {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
      border-radius: 30px;
      min-width: 200px;
      padding: 5px 12px;
      justify-content: flex-end;
      .vs-con-dropdown {
        .con-img {
          img {
            width: 48px !important;
            height: 48px !important;
            object-fit: scale-down !important;
          }
        }
      }
    }
  }
  &.navbar-increased {
    .vx-navbar {
      padding-left: 210px !important;
    }
  }
}
</style>
